export const seekerPublic = {
  'routes.homePageCandidate': '/',
  'routes.seekerlogin': '/login',
  'routes.registration': '/registration',
  'routes.candidateInfo': '/candidate-info',
  'routes.frequentlyAskedQuestions': '/faq',
  'routes.candidateFAQ': '/faq/candidates',
  'routes.candidateAdvice': '/advice/candidate',
}

const seekerPrivate = {
  'routes.onboarding': '/onboarding',
  'routes.contactdata': '/registration-confirmation',
  'routes.workexperience': '/workexperience',
  'routes.profile': '/edit-cv',
  'routes.publicprofile': '/edit-profile',
  'routes.assessments': '/assessments',
  'routes.cognitivetest': '/assessments/cognitive-test',
  'routes.psychometrictest': '/assessments/psychometric',
  'routes.assessmentssuccess': '/assessments/psyhometric/confirmation',
  'routes.candidatedashboard': '/dashboard',
  'routes.myAccount': '/account',
  'routes.consents': '/account/consents',
  'routes.languagesettings': '/settings/language',
  'routes.employerblacklist': '/settings/blacklist',
  'routes.newsletterunsubscribe': '/newsletter/unsubscribe',
  'routes.newsletterunsubscribesingle': '/newsletter/unsubscribe-single',
  'routes.newsletterunsubscribereason': '/newsletter/unsubscribe-reason',
  'routes.allincomingrequests': '/dashboard/all-requests',
  'routes.impersonateCandidate':
    '/impersonate/candidate/:userId/:impersonatedUserId',
  'routes.candidateCalendar': '/calendar/candidate',
  'routes.publiccompanyprofile': '/company/profile/:id',
  'routes.candidateCompanies': '/companies/candidate',
  'routes.employerMessages': '/messages/employers',
  'routes.seekerpsychometrictestresults': '/assessments/psychometric-results',
  'routes.startCognitiveTest': '/assessments/start-cognitive-test',
  'routes.seekerLoginConsents': '/seeker-login-consents',
}

export const employerPublic = {
  'routes.homePageEmployer': '/employers',
  'routes.employerlogin': '/employer-login',
  'routes.employerinfolanding': '/landing/employer-info',
  'routes.employerFAQ': '/faq/for-employers',
  'routes.employerAdvice': '/advice/employer',
}

export const employerPrivate = {
  'routes.employerLoginConsents': '/employer-login-consents',
  'routes.employeronboarding': '/employer-onboarding',
  'routes.companyprofile': '/company/profile',
  'routes.candidatesearch': '/candidate-search',
  'routes.employermyaccount': '/employer-account',
  'routes.employerconsents': '/employer-account/consents',
  'routes.employerlanguagesettings': '/employer-settings/language',
  'routes.proposenewtimecalendar': '/calendar/propose-new-time',
  'routes.reviewcandidate': '/review-candidate',
  'routes.psychometrictestresults':
    '/assessments/psychometric-results/:candidateId',
  // EMPLOYER DASHBOARD:
  'routes.employerdashboard': '/employer-dashboard',
  'routes.employercontactrequests': '/employer-dashboard/contact-requests',
  'routes.employercontactrequestsAll':
    '/employer-dashboard/contact-requests/all',
  'routes.employercontactrequestsPending':
    '/employer-dashboard/contact-requests/pending',
  'routes.employercontactrequestsApproved':
    '/employer-dashboard/contact-requests/approved',
  'routes.employerinterviewinvitations':
    '/employer-dashboard/interview-invitations',
  'routes.employerinterviewinvitationsAll':
    '/employer-dashboard/interview-invitations/all',
  'routes.employerinterviewinvitationsPending':
    '/employer-dashboard/interview-invitations/pending',
  'routes.employerinterviewinvitationsAccepted':
    '/employer-dashboard/interview-invitations/accepted',
  'routes.employerinterviewinvitationsCompleted':
    '/employer-dashboard/interview-invitations/completed',
  'routes.employeroffers': '/employer-dashboard/offers',
  'routes.employeroffersAll': '/employer-dashboard/offers/all',
  'routes.employeroffersPending': '/employer-dashboard/offers/pending',
  'routes.employeroffersAccepted': '/employer-dashboard/offers/accepted',
  'routes.employerplacements': '/employer-dashboard/placements',
  'routes.employerplacementsAll': '/employer-dashboard/placements/all',
  'routes.employerplacementsPending': '/employer-dashboard/placements/pending',
  'routes.employerplacementsConfirmed':
    '/employer-dashboard/placements/confirmed',
  'routes.employerthankyou': '/thank-you',
  'routes.candidateMessages': '/messages/candidates',
  'routes.employerMessagesTSA': '/messages/tsa',
  'routes.employerCalendar': '/calendar/employer',
  'routes.savedSearch': '/cv-search-agent',
  'routes.myprojects': '/my-projects',
  'routes.myprojectcandidates': '/my-projects/:id',
}

export const employerPrivateWithParam = {
  'routes.publicassessments': '/candidate/:id/assessments',
  'routes.invitecalendar': '/calendar/invite/:candidateId',
  'routes.impersonateEmployer':
    '/impersonate/employer/:userId/:impersonatedUserId',
  'routes.employerCandidateProfilePublic': '/candidate/public/:id',
  'routes.employerCandidateProfileFull': '/candidate/cv/:id',
}

export const publicRoutes = {
  'routes.helpsupport': '/support',
  'routes.helpsupportcontactus': '/support/contact',
  'routes.privacypolicy': '/privacy-policy',
  'routes.termsandconditions': '/terms-conditions',
  'routes.generalTermsAndConditions': '/general-terms-conditions',
  'routes.reportInformation': '/report-information',
  'routes.logout': '/logout',
  'routes.aboutus': '/about',
  'routes.page404': '/page/404',
  'routes.dataProtectionAgreement': '/data-protection-agreement',
  'routes.auth': '/auth',
  'routes.candidateWelcome': '/candidate-welcome',
  'routes.employerWelcome': '/employer-welcome',
  'routes.error': '/error',
}

const routes = {
  ...seekerPublic,
  ...seekerPrivate,
  ...employerPublic,
  ...employerPrivate,
  ...employerPrivateWithParam,
  ...publicRoutes,
}

export default routes
