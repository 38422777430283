export const identityProvider = {
  'identityProvider.welcomeMessage': 'Dobrodošli na Preferenci',
  'identityProvider.description': 'Prijavili se boste kot:',
  'identityProvider.changeUser': 'Izberi drugega uporabnika',
  'identityProvider.next': 'Naprej',
  'identityProvider.alreadyHaveAccount': 'Že imate račun?',
  'identityProvider.loginNow': 'Prijavite se',
  'identityProvider.unauthorizedError':
    'Napaka: poskušate se prijaviti z računom, ki nima dostopa do sekcije za delodajalce. Preverite, če ste ob registraciji vnesli elektronski naslov, na katerega ste prejeli vabilo.',
}
