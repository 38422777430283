export const identityProvider = {
  'identityProvider.welcomeMessage': 'Welcome to Preferenca',
  'identityProvider.description': 'You are logging in as:',
  'identityProvider.changeUser': 'Change user',
  'identityProvider.next': 'Next',
  'identityProvider.alreadyHaveAccount': 'Already have an account?',
  'identityProvider.loginNow': 'Login now',
  'identityProvider.unauthorizedError':
    'Error: Your account is not authorized to login to employer section of MD portal. Make sure you registered with the email you’ve received invitation to. ',
}
