import './Styling/index.scss'
import React from 'react'
import {Route} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import {appStrings, LocalizedRouter, LocalizedSwitch} from 'Services/I18n'
import {AppLanguage, AppRoute} from 'Services/I18n/Constants'
import * as pages from 'Pages'
import {RootLayoutContainer} from 'Layouts/RootLayout'
import {history} from 'Store/history'
import {ProtectedRoute} from 'Components/ProtectedRoute'
import {EmployerGuard, SeekerGuard, AnyGuard} from 'Layouts/Auth'
import {MessageConnectorContainer} from 'Components/MessageConnector'
import {HelmetProvider} from 'react-helmet-async'

export const App: React.FC = () => (
  <HelmetProvider>
    <ConnectedRouter history={history}>
      <LocalizedRouter
        languages={AppLanguage}
        appStrings={appStrings}
        defaultLanguage={AppLanguage.Slovenian}
      >
        <RootLayoutContainer>
          <LocalizedSwitch>
            <Route exact path={AppRoute.HomePageCandidate}>
              <pages.HomePageCandidate />
            </Route>
            <Route exact path={AppRoute.AboutUs}>
              <pages.AboutUs />
            </Route>
            <Route exact path={AppRoute.CandidateInfo}>
              <pages.CandidateInfo />
            </Route>
            <Route exact path={AppRoute.TermsAndConditions}>
              <ProtectedRoute
                withoutLogo
                component={pages.TermsAndConditions}
                theme="white"
                sidebarLinks
              />
            </Route>
            <Route exact path={AppRoute.ReportInformation}>
              <ProtectedRoute
                withoutLogo
                component={pages.ReportInformationContainer}
                theme="white"
              />
            </Route>
            <Route exact path={AppRoute.GeneralTermsAndConditions}>
              <ProtectedRoute
                withoutLogo
                component={pages.GeneralTermsAndConditions}
                theme="white"
              />
            </Route>
            <Route exact path={AppRoute.PrivacyPolicy}>
              <ProtectedRoute
                withoutLogo
                component={pages.PrivacyPolicyContainer}
                theme="white"
                sidebarLinks
              />
            </Route>
            <Route exact path={AppRoute.DataProtectionAgreement}>
              <ProtectedRoute
                withoutLogo
                component={pages.DataProtectionAgreement}
                theme="white"
                sidebarLinks
              />
            </Route>
            <Route exact path={AppRoute.CandidateFAQ}>
              <pages.CandidateFAQ />
            </Route>
            <Route exact path={AppRoute.Page404}>
              <pages.Page404Container />
            </Route>
            <Route exact path={AppRoute.SeekerLogin}>
              <pages.SeekerLogin />
            </Route>
            <Route exact path={AppRoute.SeekerLoginConsents}>
              <SeekerGuard>
                <pages.SeekerLoginConsentsContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.Registration}>
              <pages.Registration />
            </Route>
            <Route exact path={AppRoute.Onboarding}>
              <SeekerGuard>
                <pages.OnboardingContainer />
              </SeekerGuard>
            </Route>
            {/* <Route exact path={AppRoute.EmployerOnboarding}>
            <EmployerGuard>
              <pages.EmployerOnboardingContainer />
            </EmployerGuard>
          </Route> */}
            <Route exact path={AppRoute.ContactData}>
              <SeekerGuard>
                <pages.ContactDataContainer />
              </SeekerGuard>
            </Route>

            <Route exact path={AppRoute.Profile}>
              <SeekerGuard>
                <pages.ProfileContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.PublicProfile}>
              <SeekerGuard>
                <pages.PublicProfileContainer />
              </SeekerGuard>
            </Route>

            <Route exact path={AppRoute.Assessments}>
              <SeekerGuard>
                <pages.AssessmentsContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.PublicAssessments}>
              <EmployerGuard>
                <pages.AssessmentsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.AssessmentsSuccess}>
              <SeekerGuard>
                <pages.AssessmentsSuccessContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.Consents}>
              <SeekerGuard>
                <pages.ConsentsContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.CognitiveTest}>
              <SeekerGuard>
                <pages.CognitiveTestContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.PsychometricTest}>
              <SeekerGuard>
                <pages.PsychometricTestContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.PsychometricTestResults}>
              <EmployerGuard>
                <pages.PsychometricTestResultsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.SeekerPsychometricTestResults}>
              <SeekerGuard>
                <pages.PsychometricTestResultsContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.StartCognitiveTest}>
              <SeekerGuard>
                <pages.StartCognitiveTestContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.LanguageSettings}>
              <SeekerGuard>
                <pages.LanguageSettingsContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.MyAccount}>
              <SeekerGuard>
                <pages.MyAccountContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerLanguageSettings}>
              <EmployerGuard>
                <pages.LanguageSettingsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerMyAccount}>
              <EmployerGuard>
                <pages.MyAccountContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerConsents}>
              <EmployerGuard>
                <pages.ConsentsContainer />
              </EmployerGuard>
            </Route>

            <Route exact path={AppRoute.Logout}>
              <AnyGuard>
                <pages.LogoutContainer />
              </AnyGuard>
            </Route>
            <Route exact path={AppRoute.ImpersonateCandidate}>
              <pages.ImpresonateCandidate />
            </Route>
            <Route exact path={AppRoute.ImpersonateEmployer}>
              <pages.ImpresonateEmployer />
            </Route>

            <Route exact path={AppRoute.NewsletterUnsubscribeSingle}>
              <pages.NewsletterUnsubscribeSingleContainer />
            </Route>
            <Route exact path={AppRoute.NewsletterUnsubscribe}>
              <pages.NewsletterUnsubscribeContainer />
            </Route>
            <Route exact path={AppRoute.NewsletterUnsubscribeReason}>
              <pages.NewsletterUnsubscribeReasonContainer />
            </Route>
            <Route exact path={AppRoute.CandidateDashboard}>
              <SeekerGuard>
                <pages.CandidateDashboardContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.AllIncomingRequests}>
              <SeekerGuard>
                <pages.AllIncomingRequestsContainer />
              </SeekerGuard>
            </Route>

            <Route exact path={AppRoute.HomePageEmployer}>
              <pages.HomePageEmployer />
            </Route>
            <Route exact path={AppRoute.EmployerInfoLanding}>
              <pages.EmployerInfoLandingPage />
            </Route>
            <Route exact path={AppRoute.EmployerLogin}>
              <pages.EmployerLogin />
            </Route>
            <Route exact path={AppRoute.EmployerLoginConsents}>
              <EmployerGuard>
                <pages.EmployerLoginConsentsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerThankYou}>
              <pages.ThankYou />
            </Route>

            <Route exact path={AppRoute.EmployerMessages}>
              <SeekerGuard>
                <pages.MessageCenterContainer />
              </SeekerGuard>
            </Route>
            <Route exact path={AppRoute.CandidateMessages}>
              <EmployerGuard>
                <pages.MessageCenterContainer />
              </EmployerGuard>
            </Route>

            <Route exact path={AppRoute.HelpSupportContactUs}>
              <ProtectedRoute component={pages.HelpSupportContactUsContainer} />
            </Route>
            <Route exact path={AppRoute.HelpSupport}>
              <pages.HelpSupport />
            </Route>

            <Route exact path={AppRoute.FrequentlyAskedQuestions}>
              <ProtectedRoute component={pages.FrequentlyAskedQuestions} />
            </Route>
            <Route exact path={AppRoute.EmployerFAQ}>
              <pages.EmployerFAQ />
            </Route>

            <Route exact path={AppRoute.CandidateCalendar}>
              <SeekerGuard>
                <pages.SeekerCalendarContainer />
              </SeekerGuard>
            </Route>

            <Route exact path={AppRoute.EmployerCalendar}>
              <EmployerGuard>
                <pages.EmployerCalendarContainer />
              </EmployerGuard>
            </Route>

            <Route exact path={AppRoute.InviteCalendar}>
              <EmployerGuard>
                <pages.EmployerSeekerSpectoCalendarContainer />
              </EmployerGuard>
            </Route>

            <Route exact path={AppRoute.ProposeNewTimeCalendar}>
              <EmployerGuard>
                <pages.EmployerProposeNewTimeCalendarContainer />
              </EmployerGuard>
            </Route>

            <Route exact path={AppRoute.CandidateSearch}>
              <EmployerGuard>
                <pages.CandidateSearchContainer />
              </EmployerGuard>
            </Route>

            <Route exact path={AppRoute.EmployerBlacklist}>
              <SeekerGuard>
                <pages.EmployerBlacklistContainer />
              </SeekerGuard>
            </Route>

            <Route exact path={AppRoute.CompanyProfile}>
              <EmployerGuard>
                <pages.CompanyProfileContainer />
              </EmployerGuard>
            </Route>

            <Route path={AppRoute.PublicCompanyProfile}>
              <SeekerGuard>
                <pages.CompanyProfileContainer />
              </SeekerGuard>
            </Route>

            <Route exact path={AppRoute.EmployerDashboard}>
              <EmployerGuard>
                <pages.EmployerDashboardContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.ReviewCandidate}>
              <EmployerGuard>
                <pages.ReviewCandidateContainer />
              </EmployerGuard>
            </Route>
            {/* EMPLOYER DASHBOARD: */}
            <Route exact path={AppRoute.EmployerContactRequests}>
              <EmployerGuard>
                <pages.EmployerContactRequestsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerContactRequestsAll}>
              <EmployerGuard>
                <pages.EmployerContactRequestsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerContactRequestsPending}>
              <EmployerGuard>
                <pages.EmployerContactRequestsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerContactRequestsApproved}>
              <EmployerGuard>
                <pages.EmployerContactRequestsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerInterviewInvitations}>
              <EmployerGuard>
                <pages.EmployerInterviewInvitationsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerInterviewInvitationsAll}>
              <EmployerGuard>
                <pages.EmployerInterviewInvitationsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerInterviewInvitationsPending}>
              <EmployerGuard>
                <pages.EmployerInterviewInvitationsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerInterviewInvitationsAccepted}>
              <EmployerGuard>
                <pages.EmployerInterviewInvitationsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerInterviewInvitationsCompleted}>
              <EmployerGuard>
                <pages.EmployerInterviewInvitationsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerOffers}>
              <EmployerGuard>
                <pages.EmployerOffersContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerOffersAll}>
              <EmployerGuard>
                <pages.EmployerOffersContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerOffersPending}>
              <EmployerGuard>
                <pages.EmployerOffersContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerOffersAccepted}>
              <EmployerGuard>
                <pages.EmployerOffersContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerPlacements}>
              <EmployerGuard>
                <pages.EmployerPlacementsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerPlacementsAll}>
              <EmployerGuard>
                <pages.EmployerPlacementsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerPlacementsPending}>
              <EmployerGuard>
                <pages.EmployerPlacementsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerPlacementsConfirmed}>
              <EmployerGuard>
                <pages.EmployerPlacementsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerCandidateProfilePublic}>
              <EmployerGuard>
                <pages.EmployerCandidateProfileContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.EmployerCandidateProfileFull}>
              <EmployerGuard>
                <pages.EmployerCandidateProfileContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.MyProjects}>
              <EmployerGuard>
                <pages.MyProjectsContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.MyProjectCandidates}>
              <EmployerGuard>
                <pages.MyProjectCandidatesContainer />
              </EmployerGuard>
            </Route>
            <Route exact path={AppRoute.SavedSearch}>
              <EmployerGuard>
                <pages.SavedSearchContainer />
              </EmployerGuard>
            </Route>
            <Route path={AppRoute.Auth}>
              <pages.AuthContainer />
            </Route>
            <Route path={[AppRoute.CandidateWelcome, AppRoute.EmployerWelcome]}>
              <pages.WelcomePageContainer />
            </Route>
            <Route path={AppRoute.Error}>
              <pages.ErrorPage />
            </Route>
            <Route path="*">
              <pages.UnderConstruction />
            </Route>
          </LocalizedSwitch>
        </RootLayoutContainer>
        <MessageConnectorContainer />
      </LocalizedRouter>
    </ConnectedRouter>
  </HelmetProvider>
)
